import {
  Component,
  ElementRef,
  Inject,
  OnInit,
  PLATFORM_ID,
  ViewChild,
} from "@angular/core";
import { MOCK } from "./mock";
import { ConstanceService } from "src/app/services/constance.service";
import { Constance } from "src/app/models/constance";
import { isPlatformServer } from "@angular/common";
import { MetaService } from "@ngx-meta/core";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.scss"],
})
export class HomeComponent implements OnInit {
  mock = MOCK;
  ids: Array<String> = [];
  @ViewChild("videoRef", { static: true }) videoRef!: ElementRef;
  // style = {
  //   margin: "auto",
  //   width: "auto",
  //   "max-width": "964px",
  //   "font-weight": "400",
  //   "font-size": "2rem",
  //   color: "#000000",
  //   "line-height": "2.1rem",
  //   "text-align": "center",
  // };

  constructor(
    @Inject(PLATFORM_ID) private platformId,
    private constanceService: ConstanceService,
    private meta: MetaService,
    private translate: TranslateService
  ) {}
  // GET  the function returns /achievements

  ngOnInit() {
    this.constanceService.GetConstances().subscribe((data) => {
      this.handleConstances(data);
    });

    this.translate.currentLang == "ar"
      ? this.meta.setTitle("الشركة السعودية لتقنية المعلومات")
      : this.meta.setTitle("Saudi Information Technology Company");
  }
  ngAfterViewInit(): void {
    // var isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    // document.getElementById("videoBanner").addEventListener("suspend", () => {
    //   // suspended loading. Show play UI..
    //   if (isSafari) {
    //     document.getElementById("videoBanner").style.display = "none";
    //     document.getElementById("img").style.display = "block";
    //   }
    // });
  }

  isMobile() {
    if (!isPlatformServer(this.platformId)) {
      return window.innerWidth < 900 ? true : false;
    } else {
      return false;
    }
  }

  handleConstances(list: Constance) {
    this.mock.achievements.counter_one_content = list.PARTNERS;
    list.CLIENTS != "-1"
      ? (this.mock.achievements.counter_second_content = list.CLIENTS)
      : "";
    // this.mock.achievements.counter_second_content = list.CLIENTS;

    this.mock.achievements.counter_third_content = list.SERVICES_AND_PRODUCTS;
    //this.mock.achievements.counter_fourth_content = list.PROJECTS;
  }
}
