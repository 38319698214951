import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { MOCK } from "./mock";
import { TranslateService } from "@ngx-translate/core";
import { ProductService } from "src/app/services/product.service";
import { MetaService } from "@ngx-meta/core";

@Component({
  selector: "app-product",
  templateUrl: "./product.component.html",
  styleUrls: ["./product.component.scss"],
})
export class ProductComponent implements OnInit {
  public slug;
  public title: string;
  public description: string;
  public productsURLs = [
    "https://site.sa/contact-us",
    "https://site.sa/join-us",
    "https://site.sa/about-us",
    "https://store.demo.site.sa/public/product/d174e03f-0dab-4610-81c8-da565e617f02",
  ];
  id;
  mock = MOCK;
  lang = "en";

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private translate: TranslateService,
    private productService: ProductService,
    private meta: MetaService
  ) {}

  ngOnInit() {
    this.lang = this.translate.currentLang;
    this.route.params.forEach((param) => {
      this.slug = param.slug;
      this.productService.GetProducts().subscribe((products) => {
        const tempData = products.filter(
          (a) => a.en_title.toLowerCase().split(" ").join("_") == this.slug
        );
        if (tempData.length < 1) {
          this.router.navigate([`/${this.translate.currentLang}/404`]);
          return;
        }
        this.id = tempData[0].id;
        this.productService.GetProduct(this.id).subscribe((data) => {
          this.title =
            this.translate.currentLang == "ar" ? data.ar_title : data.en_title;
          this.description =
            this.translate.currentLang == "ar"
              ? data.ar_description
              : data.en_description;

          this.meta.setTitle(this.title);
          this.meta.setTag("description", this.description);
          this.meta.setTag("og:image", data.image);

          this.mock.description = this.description;

          if (data.product_objectives[0])
            this.mock.second_description =
              this.translate.currentLang == "ar"
                ? data.product_objectives[0].ar_description
                : data.product_objectives[0].en_description;

          this.mock.slides[0].image = data.image;
          this.mock.slides[0].headingIcon = data.logo;
          this.mock.slides[0].subtitle =
            this.translate.currentLang == "ar"
              ? data.ar_slogan
              : data.en_slogan;
          this.mock.slides[0].firstButtonText =
            this.translate.currentLang == "ar"
              ? data.ar_firstButton
              : data.en_firstButton;
          this.mock.slides[0].firstButtonLink = data.firstButtonUrl;
          this.mock.slides[0].secondButtonText =
            this.translate.currentLang == "ar"
              ? data.ar_secondButton
              : data.en_secondButton;
          this.mock.slides[0].secondButtonLink = data.secondButtonUrl;
          let url;
          if (data.en_title.includes("SITE Postal")) {
            url =
              "https://store.demo.site.sa/public/product/d174e03f-0dab-4610-81c8-da565e617f02";
          } else {
            url = this.productsURLs.find((a) => a.includes("contact"));
          }
          // this.mock.estimate.link = url;
          this.mock.estimate.link = "http://site.sa/contact-us";

          // redirects to products new website
          // this.mock.slides[0].firstButtonLink = url;
          // Add the following lines to MOCK under slides
          // firstButtonLink: 'https://site.sa/contact-us',
          // firstButtonText: 'trail.slides',

          let features = [];

          data.product_features.forEach((value: any) => {
            let subpointList = [];
            value.product_features_subpoint.forEach((subpoint) => {
              this.translate.currentLang == "ar"
                ? subpoint.ar_description.startsWith("....")
                  ? null
                  : subpointList.push(subpoint.ar_description)
                : subpoint.en_description.startsWith("....")
                ? null
                : subpointList.push(subpoint.en_description);
            });

            if (
              this.translate.currentLang == "ar" &&
              !value.ar_description.startsWith("....")
            ) {
              features.push({
                title: value.ar_description,
                subpoints: subpointList,
              });
            }
            if (
              this.translate.currentLang == "en" &&
              !value.en_description.startsWith("....")
            ) {
              features.push({
                title: value.en_description,
                subpoints: subpointList,
              });
            }
          });

          this.mock.features = features;
          this.mock.benefits = data.product_benefits;

          if (this.mock.breadcrumbs.length == 3) {
            this.mock.breadcrumbs.pop();
          }

          this.mock.breadcrumbs.push({
            link: "/products/" + this.slug,
            title: this.title,
          });
        });
      });
    });
  }
}
