import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { MOCK } from "./mock";
import { TranslateService } from "@ngx-translate/core";
import { ProductService } from "src/app/services/product.service"; //delete
import { SubserviceService } from "src/app/services/subservice.service";
import { BusinessLineService } from "src/app/services/business-line.service";
import { MetaService } from "@ngx-meta/core";
import anime from "animejs/lib/anime.es.js";

@Component({
  selector: "app-subservice",
  templateUrl: "./subservice.component.html",
  styleUrls: ["./subservice.component.scss"],
})
export class SubserviceComponent implements OnInit {
  public slug;
  public subslug;
  public title: string;
  public description: string;
  id;
  slugId;
  active;
  mock = MOCK;
  lang = "en";
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private translate: TranslateService,
    private SubserviceService: SubserviceService,
    private businessLineService: BusinessLineService,
    private meta: MetaService
  ) {}

  ngOnInit() {
    let subservice = [];
    let info = [];
    let tempData: any;
    let data: any;
    this.route.params.forEach((param) => {
      this.subslug = param.subslug;
      this.slug = param.slug;
      // breadcrumps
      // this.mock.breadcrumbs = []
      if (this.mock.breadcrumbs.length == 4) {
        this.mock.breadcrumbs.pop();
      }
      if (this.mock.breadcrumbs.length == 3) {
        this.mock.breadcrumbs.pop();
      }

      this.SubserviceService.GetSubservices().subscribe((subservices) => {
        tempData = subservices.filter(
          (a) =>
            a.en_title.toLowerCase().split(" ").join("_") ==
            this.subslug.toLowerCase()
        );
        if (tempData.length < 1) {
          this.router.navigate([`/${this.translate.currentLang}/404`]);
          return;
        }
        data = tempData[0];

        this.businessLineService.GetBusinessLines().subscribe((lines) => {
          const tempData = lines.filter(
            (a) => a.en_title.toLowerCase().split(" ").join("_") == this.slug
          );
          this.slugId = tempData[0].id;

          this.mock.breadcrumbs.push({
            link: "/services-and-solutions/" + this.slug,
            title:
              this.translate.getDefaultLang() == "ar"
                ? tempData[0].ar_title
                : tempData[0].en_title,
          });
          this.mock.breadcrumbs.push({
            link: "/services-and-solutions/" + this.slug + "/" + this.subslug,
            title:
              this.translate.getDefaultLang() == "ar"
                ? data.ar_title
                : data.en_title,
          });
          this.translate.getDefaultLang() == "ar"
            ? this.meta.setTitle(data.ar_title)
            : this.meta.setTitle(data.en_title);
        });

        this.lang = this.translate.getDefaultLang() == "ar" ? "arr" : "enn";
        this.mock.title =
          this.translate.getDefaultLang() == "ar"
            ? data.ar_title
            : data.en_title;
        this.mock.description =
          this.translate.getDefaultLang() == "ar"
            ? data.ar_description
            : data.en_description;
        this.mock.slides[0].image = data.image;
        this.mock.slides[0].headingIcon = data.logo;
        this.mock.slides[0].heading =
          this.translate.getDefaultLang() == "ar"
            ? data.ar_title
            : data.en_title;
        this.mock.slides[0].subtitle =
          this.translate.getDefaultLang() == "ar"
            ? data.ar_sub_description
            : data.en_sub_description;
        this.mock.slides[0].firstButtonText =
          this.translate.getDefaultLang() == "ar"
            ? data.ar_firstButton
            : data.en_firstButton;
        this.mock.slides[0].firstButtonLink = data.firstButtonUrl;
        this.mock.slides[0].secondButtonText =
          this.translate.getDefaultLang() == "ar"
            ? data.ar_secondButton
            : data.en_secondButton;
        this.mock.slides[0].secondButtonLink = data.secondButtonUrl;
        //subservices
        data.sub_service.forEach((value, i) => {
          let subpointList = [];
          let benefitList = [];

          value.sub_service_sub_points.forEach((subpoint) => {
            if (this.translate.getDefaultLang() == "ar") {
              subpoint.ar_description.includes("$&")
                ? subpointList.push({
                    title: subpoint.ar_title,
                    descriptionArray: subpoint.ar_description.split("$&"),
                  })
                : subpointList.push({
                    title: subpoint.ar_title,
                    description: subpoint.ar_description,
                  });
            } else {
              subpoint.ar_description.includes("$&")
                ? subpointList.push({
                    title: subpoint.en_title,
                    descriptionArray: subpoint.en_description.split("$&"),
                  })
                : subpointList.push({
                    title: subpoint.en_title,
                    description: subpoint.en_description,
                  });
            }
          });
          //benefits
          value.sub_service_benefit.forEach((benefit) => {
            this.translate.getDefaultLang() == "ar"
              ? benefitList.push(benefit.ar_description)
              : benefitList.push(benefit.en_description);
          });
          subservice.push({
            title:
              this.translate.getDefaultLang() == "ar"
                ? value.ar_title
                : value.en_title,
            description:
              this.translate.getDefaultLang() == "ar"
                ? value.ar_description
                : value.en_description,
            image: value.image,
            subpoints: subpointList,
            benefits: benefitList,
            background: i % 2 == 0 ? "#FFFFFF" : "#F5F5F5",
          });
        });
        data.sub_service_extra_info.forEach((partner) => {
          info.push({
            description:
              this.translate.getDefaultLang() == "ar"
                ? partner.ar_description
                : partner.en_description,
            url_title:
              this.translate.getDefaultLang() == "ar"
                ? partner.ar_url_title
                : partner.en_url_title,
            icon: partner.icon,
            url: partner.url,
          });
        });

        this.mock.sub_services = subservice;
        this.mock.info = info;
        // this.mock.breadcrumbs = []

        // this.mock.breadcrumbs.push({
        //   link: "/" + this.slug,
        //   title: (this.translate.getDefaultLang() == 'ar') ? data.ar_title : data.en_title
        // });
        // this.mock.breadcrumbs.push({
        //   link: "/" + this.subslug,
        //   title: (this.translate.getDefaultLang() == 'ar') ? data.ar_title : data.en_title
        // });
      });
    });
  }
  //nav
  chooseItem(anchor: string) {
    this.active = anchor;

    const { top } = document.querySelector(anchor).getBoundingClientRect();

    const navHeight = 110;
    const scrollCoords = {
      y: window.pageYOffset,
    };

    anime({
      targets: scrollCoords,
      y: top + window.pageYOffset - navHeight,
      duration: 500,
      easing: "easeInOutCubic",
      update: () => window.scroll(0, scrollCoords.y),
    });
  }
}
